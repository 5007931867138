@import "variables";

@media (min-width: 1200px) {
  .container {
    width: 960px;
  }
}

html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  position: relative;
  font-family: $font-family-sans-serif;
}

.page {
  float: left;
  min-height: 100%;
}

.fp-slidesContainer {
  margin-top: 10px;
}
.fp-scrollable {
  padding-bottom: 110px; /* must make accomodations due to the fixed footer */
}
.fp-controlArrow {
  bottom: 300px;
  top: auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 70px;
  height: 50px;
  background-color: $vopak-red;
}
.fp-controlArrow.fp-next {
  right: 5%;
  background-image:url(#{$bg-img-path}/arrow-next.png);
  border-width: 0;
}

.fp-controlArrow.fp-prev {
  left: 5%;
  background-image:url(#{$bg-img-path}/arrow-prev.png);
  width: 70px;
  border-width: 0;
}
.fp-controlArrow:hover {
    opacity: 0.75;
}

#fit {
  background: $gray-lighter url('#{$bg-img-path}/widebanner.png') no-repeat scroll 50% 0%;
  height: 100px;
}

.banner-title {
  text-align: center;
}

.logo-container {
  padding-top: 15px;
  padding-bottom: 15px;
}

h1 {
  font-size:3.83em;
  letter-spacing: -0.070em;
  color: #000;
  display: inline-block;
  padding-right: 23px;
}

h1::after {
  content: " ";
  display: inline-block;
  background-color: #e23130;
  width: 0.25em;
  height: 0.25em;
  margin-left: 0.075em;
  margin-bottom: -0.0125em;
  border-radius: 50%;
  line-height: 1;
}

.slide p,
.slide li {
  font-size: $slide-content-font-size;
  line-height: 1.1;
}
.slide li {
  line-height: 1.4;
}

.footer-separator {
  background: url(#{$bg-img-path}/grey-dot.png) left top;
  height: 48px;
  border-bottom: 1px solid $gray-lighter;
}
.navbar-footer {
  background-color: $gray-lightest;
}

.navbar-footer .container {
  color: $gray;
  font-size: 0.9em;
}

div.section {
  margin-top: 200px;
}

.navbar {
  height: 110px;
}

.top-20 {
  margin-top: 20px;
}

.presentation-images > div {
    height: 180px;
}
.presentation-images > div img {
    max-height: 130px;
    max-width: 200px;
}

.modal-header .close {
    color:#000;
}